import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

export default function PaymentPortal() {
  const [formData, setFormData] = useState({
    name_first: '',
    name_last: '',
    email_address: '',
    cell_number: '',
    item_name: 'Chirosport Subscription',
    amount: '5800.00',
    subscription_type: '1',
    billing_date: new Date().toISOString().split('T')[0], // Today's date
    frequency: '3',
    cycles: '0'
  });

  const [selectedPlan, setSelectedPlan] = useState('once_off');

  useEffect(() => {
    console.log('PaymentPortal component mounted');
    return () => {
      console.log('PaymentPortal component will unmount');
    };
  }, []);

  useEffect(() => {
    console.log('Form data updated:', formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Field '${name}' changed:`, value);
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePlanChange = (e) => {
    const plan = e.target.value;
    setSelectedPlan(plan);
    let amount, frequency, cycles;

    switch (plan) {
      case 'once_off':
        amount = '5800.00';
        frequency = '0';
        cycles = '1';
        break;
      case '3_months':
        amount = (5800 / 3).toFixed(2);
        frequency = '3';
        cycles = '3';
        break;
      case '6_months':
        amount = (5800 / 6).toFixed(2);
        frequency = '3';
        cycles = '6';
        break;
      case '12_months':
        amount = (5800 / 12).toFixed(2);
        frequency = '3';
        cycles = '12';
        break;
      default:
        amount = '5800.00';
        frequency = '0';
        cycles = '1';
    }

    setFormData(prevState => ({
      ...prevState,
      amount,
      frequency,
      cycles,
      subscription_type: plan === 'once_off' ? '0' : '1'
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Send form data to PayFast
    const form = e.target;
    form.submit();

    // Send payment notification to our server
    try {
      const response = await fetch('/api/payment-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: `${formData.name_first} ${formData.name_last}`,
          email: formData.email_address,
          amount: parseFloat(formData.amount),
          status: 'pending',
          paymentType: selectedPlan,
          frequency: formData.frequency,
          nextPaymentDate: formData.billing_date,
          itemName: formData.item_name,
          itemDescription: `Chirosport ${selectedPlan === 'once_off' ? 'One-time Payment' : 'Subscription'}`,
          membership: formData.membership
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send payment notification');
      }

      console.log('Payment notification sent successfully');
    } catch (error) {
      console.error('Error sending payment notification:', error);
    }
  };

  console.log('Rendering PaymentPortal component');

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <main className="flex-grow flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-chiro-blue">
              Payment Form
            </h2>
          </div>
          {/* <form onSubmit={handleSubmit} action="https://sandbox.payfast.co.za/eng/process" method="POST" className="mt-8 space-y-6"> */}
          <form onSubmit={handleSubmit} action="https://www.payfast.co.za/eng/process" method="POST" className="mt-8 space-y-6">
            {/* PayFast merchant details */}
            {/* <input type="hidden" name="merchant_id" value="10000100" />  */}
            {/* <input type="hidden" name="merchant_key" value="46f0cd694581a" />  */}
            <input type="hidden" name="merchant_id" value="16502607" />
            <input type="hidden" name="merchant_key" value="ud5fyi9rbas6k" />
            <input type="hidden" name="return_url" value="http://chirosportsaevents.co.za/#/return" />
            <input type="hidden" name="cancel_url" value="http://chirosportsaevents.co.za/#/cancel" />
            <input type="hidden" name="notify_url" value="http://chirosportsaevents.co.za/#/notify" />

            {/* Subscription details */}
            <input type="hidden" name="item_name" value={formData.item_name} />
            <input type="hidden" name="amount" value={formData.amount} />
            <input type="hidden" name="subscription_type" value={formData.subscription_type} />
            <input type="hidden" name="billing_date" value={formData.billing_date} />
            <input type="hidden" name="frequency" value={formData.frequency} />
            <input type="hidden" name="cycles" value={formData.cycles} />

            <div className="space-y-6">
              <div>
                <label htmlFor="name_first" className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  id="name_first"
                  name="name_first"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-chiro-blue focus:border-chiro-blue sm:text-sm"
                  value={formData.name_first}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="name_last" className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  id="name_last"
                  name="name_last"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-chiro-blue focus:border-chiro-blue sm:text-sm"
                  value={formData.name_last}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Email Address</label>
                <input
                  id="email_address"
                  name="email_address"
                  type="email"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-chiro-blue focus:border-chiro-blue sm:text-sm"
                  value={formData.email_address}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="cell_number" className="block text-sm font-medium text-gray-700">Mobile Number</label>
                <input
                  id="cell_number"
                  name="cell_number"
                  type="tel"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-chiro-blue focus:border-chiro-blue sm:text-sm"
                  value={formData.cell_number}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="membership" className="block text-sm font-medium text-gray-700">Membership</label>
                <input
                  id="membership"
                  name="membership"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-chiro-blue focus:border-chiro-blue sm:text-sm"
                  value={formData.membership}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Select Payment Plan</label>
              <select
                name="payment_plan"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-chiro-blue focus:border-chiro-blue sm:text-sm"
                value={selectedPlan}
                onChange={handlePlanChange}
              >
                <option value="once_off">Once-off R5800</option>
                <option value="3_months">3 months (R{(5800 / 3).toFixed(2)} per month)</option>
                <option value="6_months">6 months (R{(5800 / 6).toFixed(2)} per month)</option>
                <option value="12_months">12 months (R{(5800 / 12).toFixed(2)} per month)</option>
              </select>
            </div>

            <div className="flex items-center">
              <input
                id="agreeTerms"
                name="agreeTerms"
                type="checkbox"
                required
                className="h-4 w-4 text-chiro-blue focus:ring-chiro-blue border-gray-300 rounded"
              />
              <label htmlFor="agreeTerms" className="ml-2 block text-sm text-gray-900">
                I agree to the {' '}
                <Link to="/terms" target="_blank" rel="noopener noreferrer" className="text-chiro-blue hover:text-chiro-red">
                  terms and conditions
                </Link>
              </label>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-chiro-blue hover:bg-chiro-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chiro-blue"
              >
                Proceed to Payment
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}